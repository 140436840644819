
$skeletonLoadingGradientWidth: 1200px;
$skeletonLoadingGradient: linear-gradient(to right, rgba(#8c8c8c, 0.05) 0%, rgba(#8c8c8c, 0.1) 15%, rgba(#8c8c8c, 0.05) 30%);

@keyframes loading {
  0% {
    background-position: -$skeletonLoadingGradientWidth 0;
  }
  100% {
    background-position: $skeletonLoadingGradientWidth 0;
  }
}

.loading {
  animation: loading 3s linear;
  animation-iteration-count: infinite;
  background-image: $skeletonLoadingGradient;
  background-size: $skeletonLoadingGradientWidth 100%;

  &.loading-1 {
    width: 100%;
    height: 10rem;
    border-radius: 3px;
    margin: 0.25rem 0;
  }
  
  &.loading-2 {
    width: 100%;
    height: 3rem;
    margin: 0.25rem 0;
  }
}
