@keyframes pulse {
    0% {
      opacity: 0.8;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 0.8;
    }
  }
  